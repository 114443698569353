import React, { FC, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { FormRenderProps } from 'react-final-form';
import { Button } from '@/components/ui/Button';
import { Loader } from '@/components/ui/Loader';
import { LanguageSelector } from '@/components/landing/CountrySelectorModal/CountrySelectorElements/LanguageSelector';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { SubDomainFeatures } from '@/controllers/features/subDomainFeature.config';
import { useIsFeaturesEnabled } from '@/controllers/features/features.hooks/useIsFeaturesEnabled';
import { TimezoneSelector } from '../CountrySelector/TimezoneSelector';

const LocationSelector = dynamic(
  () => import('@/components/landing/CountrySelectorModal/CountrySelectorElements/LocationSelector')
    .then((mod) => mod.LocationSelector),
);

type Props = FormRenderProps;

export const CountrySelectorForm: FC<Props> = ({
  handleSubmit, submitting,
}) => {
  const { t } = useTranslation([I18N_CODES.countrySelector]);
  const { subDomain, isKnowelyProduct } = useSubDomainContext();
  const isLocaleSelectorsInSettingsEnabled = useIsFeaturesEnabled(
    SubDomainFeatures.LocaleSelectorsInSettings,
  );
  const shouldLocationSelectorBeVisible = isLocaleSelectorsInSettingsEnabled
    && !isKnowelyProduct;
  const [authUser] = useAuthUser({ ssr: true });

  const [selectedDomain, setSelectedDomain] = useState(subDomain);

  const [selectedTimezone, setSelectedTimezone] = useState(
    authUser?.timezone,
  );

  useEffect(() => {
    if (!selectedTimezone && authUser?.timezone) {
      setSelectedTimezone(authUser?.timezone);
    }
  }, [authUser?.timezone, selectedTimezone]);

  return (
    <form
      onSubmit={handleSubmit}
      className='position-relative'
      data-qa='country-selector-form'
    >

      {
        shouldLocationSelectorBeVisible && (
          <div className="mb-16">
            <LocationSelector
              domain={subDomain}
              setDomain={setSelectedDomain}
            />
          </div>
        )
      }

      {authUser && (
        <div className="mb-16">
          <TimezoneSelector
            timezone={selectedTimezone}
            setTimezone={setSelectedTimezone}
          />
        </div>
      )}

      <div className="mb-32">
        <LanguageSelector domain={selectedDomain} />
      </div>

      <Button
        hasFullWidth
        type="submit"
        mode={Button.mode.Primary}
        size={Button.size.Large}
        text={t(`${I18N_CODES.countrySelector}:country_selector_button`)}
        data-qa='country-selector-submit-button'
      />

      <Loader
        loading={submitting}
        size={34}
      />
    </form>
  );
};
