import React from 'react';
import { NextPage, NextPageContext } from 'next';
import { PageProps } from '@/controllers/page/page.typedefs';
import { setDisplayName } from '@/controllers/page/setDisplayName';
import { I18N_CODES } from '@/lib/constants/general';

type GetInitialPropsType<P> = (ctx: NextPageContext) => Promise<P>;

export const withNamespaces = <Props extends PageProps = PageProps>(
  namespaces: Array<I18N_CODES>,
) => (
    PageComponent: NextPage<Props>,
  ): NextPage<Props> => {
    const WithNamespaces = (pageProps: Props) => (
      <PageComponent {...pageProps} />
    );

    setDisplayName(PageComponent, WithNamespaces, 'withNamespaces');

    const getInitialProps: GetInitialPropsType<Props> = async (
      ctx,
    ): Promise<Props> => {
      let pageProps = {} as Props;

      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx);
      }

      const { namespacesRequired = [] } = pageProps;

      return {
        ...pageProps,
        namespacesRequired: [
          ...new Set([
            ...namespacesRequired,
            ...namespaces,
          ]),
        ],
      };
    };

    WithNamespaces.getInitialProps = getInitialProps;

    return WithNamespaces;
  };
